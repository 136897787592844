<template>
  <div class="d-flex align-items-center">
    <span class="mr-1 text-capitalize">{{ $t("show") }}</span>
    <b-form-group class="w-100">
      <v-select
        :components="{ OpenIndicator }"
        class="bg-white"
        :clearable="false"
        v-model="perPage"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="perPageOptions"
      />
    </b-form-group>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { BFormGroup } from "bootstrap-vue";

export default {
  props: ["value"],
  components: {
    vSelect,
    BFormGroup,
  },
  data() {
    return {
      OpenIndicator: {
        render: (createElement) =>
          createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      perPage: 10,
      perPageOptions: [1,2,3,10, 20, 50],
    };
  },
  watch: {
    perPage(newVal) {
      this.$emit("input", this.perPage);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
