<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
            @click="showFormModal(null)"
            class="px-3 text-capitalize"
            variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          ایجاد کارمند/پرسنل
        </b-button>
      </b-col>
    </b-row>
    <div v-if="clerks">
      <div class="d-flex justify-content-between align-items-center">
        <per-page class="col-sm-12 col-md-3 col-lg-2" v-model="perPage" />
        <!-- <search
          ref="residencesSearchComponent"
          class="w-50"
          v-model="searchInput"
        /> -->
      </div>

      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th>نام و نام خانوادگی</b-th>
            <b-th>کد پرسنلی</b-th>
            <b-th>شماره</b-th>
            <b-th>واحد سازمانی</b-th>
            <b-th>حقوق ماهیانه</b-th>
            <b-th>وضعیت</b-th>
            <b-th>تاریخ</b-th>
            <b-th>عملیات</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="item in clerks" :key="item.id">
            <b-td>{{ item.first_name }}</b-td>
            <b-td>{{ item.code }}</b-td>
            <b-td>
                {{ item.mobile }}
            </b-td>
            <b-td>{{ item.organizational_unit }}</b-td>
            <b-td>
              {{ numberFormat(item.monthly_salary) }}
              تومان
            </b-td>
            <b-td>
              <b-col
                  class="d-flex align-items-center pl-0"
                  :class="
                  item.status != 'active' ? 'text-danger' : 'text-success'
                "
              >
                {{ item.status == "active" ? "" : "غیر" }}فعال
              </b-col></b-td
            >
            <b-td>
              {{
                new Date(item.created_at).toLocaleString("fa-IR", {
                  timeZone: "Iran",
                })
              }}
            </b-td>


            <b-td>
              <b-col class="d-flex align-items-center">
<!--                <b-button
                    @click="showConfirmModal(item)"
                    variant="flat-danger"
                    class="font-weight-bolder"
                >
                  <feather-icon icon="Trash2Icon" size="20" />
                </b-button>-->
                <b-button
                    @click="showFormModal(item)"
                    variant="flat-secondary"
                    class="font-weight-bolder"
                >
                  <feather-icon icon="Edit2Icon" size="20" />
                </b-button> </b-col
              ></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <pagination
          ref="residencesPaginationComponent"
          v-model="page"
          :paginationData="paginationData"
      />
    </div>

    <b-sidebar
        width="900px"
        v-model="formModal"
        right
        backdrop
        shadow
        bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
                style="background-color: rgba(75, 75, 75, 0.12)"
                @click="formModal = false"
                variant="flat-dark"
                class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? "ویرایش کارمند" : "ایجاد کارمند" }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class="row">
        <b-form-group class="col-md-6">
          <label for="name" class="text-capitalize">
            نام : <span class="text-danger">*</span></label
          >
          <b-form-input
              @input="removeErrors('first_name')"
              id="name"
              v-model="clerkInput.first_name"
              type="text"
          />
          <ErrorMessage
              errorType="name"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="col-md-6">
          <label for="name" class="text-capitalize">
            نام خانوادگی: <span class="text-danger">*</span></label
          >
          <b-form-input
              @input="removeErrors('last_name')"
              id="name"
              v-model="clerkInput.last_name"
              type="text"
          />
          <ErrorMessage
              errorType="name"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="col-md-6">
          <label for="name" class="text-capitalize">
            شماره موبایل: <span class="text-danger">*</span></label
          >
          <b-form-input
              @input="removeErrors('mobile')"
              id="name"
              v-model="clerkInput.mobile"
              type="text"
          />
          <ErrorMessage
              errorType="name"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="col-md-6">
          <label for="name" class="text-capitalize">
            کد پرسنلی: <span class="text-danger">*</span></label
          >
          <b-form-input
              @input="removeErrors('code')"
              id="name"
              v-model="clerkInput.code"
              type="text"
          />
          <ErrorMessage
              errorType="name"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>

        <b-form-group class="col-md-6">
          <label for="residence_area" class="text-capitalize">
            جنسیت :<span class="text-danger">*</span>
          </label>
          <v-select
              id="residence_area"
              :components="{ OpenIndicator }"
              class="bg-white"
              :clearable="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="['آقا', 'خانم']"
              v-model="clerkInput.gender"
          />
        </b-form-group>



        <b-form-group class="col-md-6">
          <label for="residence_area" class="text-capitalize">
            شیفت کاری :<span class="text-danger">*</span>
          </label>
          <v-select
              id="residence_area"
              :components="{ OpenIndicator }"
              class="bg-white"
              :clearable="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="['ستادی','سبخ','ساعتی', 'اداری']"
              v-model="clerkInput.shift_work"
          />
        </b-form-group>
        <b-form-group class="col-md-6">
          <label for="residence_area" class="text-capitalize">
            واحد سازمانی :<span class="text-danger">*</span>
          </label>
          <v-select
              id="residence_area"
              :components="{ OpenIndicator }"
              class="bg-white"
              :clearable="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="['نظافت چی','پشتیبان','متصدی خرید','باربری','راننده','پیک','کافه','سرآشپز','کشتی تفریحی','خواننده','نگهبان','اپراتور', 'مدیریت']"
              v-model="clerkInput.organizational_unit"
          />
        </b-form-group>
        <b-form-group class="col-md-6">
          <label for="name" class="text-capitalize">
            حقوق پایه ماهیانه به تومان: <span class="text-danger">*</span></label
          >
          <b-form-input
              @input="removeErrors('monthly_salary')"
              id="name"
              v-model="clerkInput.monthly_salary"
              type="text"
          />
          <span v-if="clerkInput.monthly_salary>0">
            {{numberFormat(clerkInput.monthly_salary)}}
          </span>
          <ErrorMessage
              errorType="name"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="col-md-6">
          <label for="name" class="text-capitalize">
            دستمزد روزانه به تومان(اختیاری) :
          </label
          >
          <b-form-input
              @input="removeErrors('monthly_daily')"
              id="name"
              v-model="clerkInput.monthly_daily"
              type="text"
          />
          <span v-if="clerkInput.monthly_daily>0">
            {{numberFormat(clerkInput.monthly_daily)}}
          </span>
          <ErrorMessage
              errorType="name"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="col-md-6">
          <label for="name" class="text-capitalize">
            پاداش/انعام به تومان(اختیاری) : </label
          >
          <b-form-input
              @input="removeErrors('reward')"
              id="name"
              v-model="clerkInput.reward"
              type="text"
          />
          <span v-if="clerkInput.reward>0">
            {{numberFormat(clerkInput.reward)}}
          </span>
          <ErrorMessage
              errorType="name"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <hr/>
        <b-form-group class="col-md-6">
          <label for="name" class="text-capitalize">
            شماره حساب(اختیاری) : </label
          >
          <b-form-input
              @input="removeErrors('bank_account')"
              id="name"
              v-model="clerkInput.bank_account"
              type="text"
          />
          <ErrorMessage
              errorType="name"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="col-md-6">
          <label for="name" class="text-capitalize">
            شماره شبا(اختیاری) : </label
          >
          <b-form-input
              @input="removeErrors('bank_sheba')"
              id="name"
              v-model="clerkInput.bank_sheba"
              type="text"
          />
          <ErrorMessage
              errorType="name"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="col-md-6">
          <label for="name" class="text-capitalize">
            شماره کارت (اختیاری) : </label
          >
          <b-form-input
              @input="removeErrors('bank_number')"
              id="name"
              v-model="clerkInput.bank_number"
              type="text"
          />
          <ErrorMessage
              errorType="name"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="col-md-6">
          <label for="residence_area" class="text-capitalize">
            نام بانک :<span class="text-danger">*</span>
          </label>
          <v-select
              id="residence_area"
              :components="{ OpenIndicator }"
              class="bg-white"
              :clearable="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="['ملی','ملت','سپه', 'تجارت','پارسیان','پاسارگارد','سامان','کشاورزی','گردشگری','سایر']"
              v-model="clerkInput.bank_name"
          />
        </b-form-group>
        <b-form-group class="col-md-12">
          <label for="name" class="text-capitalize">
            آدرس  (اختیاری) : </label
          >
          <b-form-input
              @input="removeErrors('address')"
              id="name"
              v-model="clerkInput.address"
              type="text"
          />
          <ErrorMessage
              errorType="name"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="col-md-12">
          <label for="name" class="text-capitalize">
            توصیحات تکمیلی  (اختیاری) : </label>
          <b-form-textarea
              @input="removeErrors('description')"
              id="name"
              v-model="clerkInput.description"
              type="text"
          />
          <ErrorMessage
              errorType="name"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>

        <b-form-group class="mt-1 col-md-12" v-slot="{ ariaDescribedby }">
          <label for="province_status" class="text-capitalize"> وضعیت : </label>
          <b-form-checkbox
              v-model="clerkInput.status"
              :aria-describedby="ariaDescribedby"
              value="active"
              @input="removeErrors('status')"
          >فعال</b-form-checkbox
          >
          <b-form-checkbox
              v-model="clerkInput.status"
              :aria-describedby="ariaDescribedby"
              value="inactive"
              @input="removeErrors('status')"
          >پیش نویس</b-form-checkbox
          >
          <ErrorMessage
              errorType="status"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
              class="text-capitalize mr-2"
              @click="formModal = false"
              variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BFormCheckbox,
  BTableSimple,
  BThead,
  BTbody,
  BFormSelect,
  BTr,
  BTh,
  BTd,
  BFormCheckboxGroup,
  BFormSpinbutton,
  BTime,
  BInputGroup,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import ErrorMessage from "@/components/ErrorMessage.vue";
import FilePicker from "@/components/FilePicker.vue";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";

export default {
  components: {
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    BFormSelect,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    ErrorMessage,
    BFormCheckbox,
    FilePicker,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BFormCheckboxGroup,
    BFormSpinbutton,
    BTime,
    datePicker: VuePersianDatetimePicker,
    BInputGroup,
  },
  data() {
    return {

      iconImageurl: "",
      errorMessage: {},
      perPage: 10,
      page: 1,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,

      tableHeadings: [
        {
          title: "تصویر آیکن",
          slug: "image_icon",
          sortable: false,
        },
        {
          title: "آیکن",
          slug: "icon",
          sortable: false,
        },
        {
          title: "عنوان",
          slug: "name",
          sortable: true,
        },
        {
          title: "توضیحات",
          slug: "description",
          sortable: true,
        },
        {
          title: "نمایش در صفحه",
          slug: "show_index_page",
          sortable: true,
        },
        {
          title: "تاریخ ایجاد",
          slug: "created_at",
          sortable: true,
        },

        {
          title: "وضعیت",
          slug: "status",
          sortable: false,
        },
        {
          title: "actions",
          sortable: false,
        },
      ],

      OpenIndicator: {
        render: (createElement) =>
            createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      companies: [],
      buildingsCancelToken: undefined,
      residences: [],
      clerkInput: {
        id: "",
        first_name: "",
        last_name: "",
        space_id : "",
        code: "",
        gender: "",
        address: "",
        description: "",
        shift_work: "",
        mobile: "",
        organizational_unit: "",
        monthly_salary: "",
        monthly_daily: "",
        reward: "",
        bank_account: "",
        bank_sheba: "",
        bank_number: "",
        bank_name: "",
        status: "",
      },
      editMode: false,
      formModal: false,
      submitLoading: false,
      clerks: [],
      removeServicesBtnIsClicked: false,
      clickOnServiceSpinBtn:false,
      discounts: 0,
      removeDiscountBtnIsClicked: false,
      clickOnDiscountSpinBtn:false,
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getClerks();
   // this.getExperiences();
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getClerks();
    },
    perPage(value) {
      this.page = 1;
      this.getClerks();
    },
    page(value) {
      this.getClerks();
    },
  },
  methods: {
    numberFormat(number) {
      return  new Intl.NumberFormat().format(number)
    },
    inputIconImageFile(file) {
      this.removeErrors("image");
      this.clerkInput.image = file;
    },
    removeErrors(data) {
      if (
          typeof this.errorMessage[data] !== "undefined" &&
          this.errorMessage[data].length > 0
      ) {
        // this.errorMessage[data][0]="salllam"
        delete this.errorMessage[data];
        this.errorMessage = Object.assign(
            {},
            this.errorMessage,
            this.errorMessage
        );
        console.log(data, this.errorMessage);
      }
    },
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getClerks();
    },

    async getClerks() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        // search: this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/clerks",
        // params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.clerks = data.data;
            this.paginationData = data.meta;
          })
          .catch((err) => {
            console.log(err);
          });
    },



/*    getExperiences(search, loading) {
      if (typeof this.usersCancelToken != typeof undefined) {
        this.usersCancelToken.cancel(
            "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.usersCancelToken = this.$http.CancelToken.source();

      let data = {
        // search: this.searchInput,
        per_page: this.perPage,
        page: this.page,

        paginate:true
      };
      const requestData = {
        method: "get",
        url: "/admin/spaces",
        params: data,
        // cancelToken: this.usersCancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.residences = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },*/


    showConfirmModal(clerk) {
      this.$bvModal
          .msgBoxConfirm(
              `آیا از حذف پرسنل مطمئن هستید ؟  : ${clerk.name}.`,
              {
                title: this.$t("Please Confirm"),
                size: "sm",
                okVariant: "primary",
                okTitle: this.$t("Yes"),
                cancelTitle: this.$t("No"),
                cancelVariant: "outline-secondary",
                hideHeaderClose: false,
                centered: true,
              }
          )
          .then(async (confirmed) => {
            if (confirmed) {
              let residenceIndex = 0;
              this.clerks = await this.clerks.filter(
                  (residenceItem, index) => {
                    if (residenceItem.id == clerk.id) {
                      residenceIndex = index;
                    }
                    return residenceItem.id !== clerk.id;
                  }
              );

              const requestData = {
                method: "delete",
                url: `/admin/clerks/${clerk.id}`,
              };
              this.$http(requestData)
                  .then((response) => {
                    this.$bvToast.toast(
                        `پرسنل: ${clerk.name}  با موفقیت حذف شد.`,
                        {
                          title: this.$t("Done"),
                          variant: "success",
                          solid: true,
                        }
                    );
                  })
                  .catch((err) => {
                    let message = this.$t("Unable to delete!");
                    if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    )
                      message = err.response.data.message;
                    this.$bvToast.toast(message, {
                      title: this.$t("Error"),
                      variant: "danger",
                      solid: true,
                    });
                    this.clerks.splice(residenceIndex, 0, clerk);
                  });
            }
          });
    },
    showFormModal(clerk) {
      if (clerk) {
        this.clerkInput = {
          id: clerk.id,
          first_name: clerk.first_name,
          last_name:clerk.last_name,
          space_id:clerk.space_id,
          code:clerk.code,
          gender:clerk.gender,
          shift_work:clerk.shift_work,
          mobile:clerk.mobile,
          organizational_unit:clerk.organizational_unit,
          monthly_salary:clerk.monthly_salary,
          monthly_daily:clerk.monthly_daily,
          reward:clerk.reward,
          bank_account:clerk.bank_account,
          bank_sheba:clerk.bank_sheba,
          bank_number:clerk.bank_number,
          bank_name:clerk.bank_name,
          description: clerk.description,
          address: clerk.description,
          status: clerk.status,
        //  image: null,
        };
        this.editMode = true;
      } else {
        this.clerkInput = {
          first_name: "",
          last_name: "",
          space_id: "",
          code: "",
          shift_work: "",
          mobile: "",
          organizational_unit: "",
          monthly_salary: "",
          monthly_daily: "",
          reward: "",
          bank_account: "",
          bank_sheba: "",
          bank_number: "",
          bank_name: "",
          description: "",
          address: "",
          status: "",
        //  image: "",
        };
       // this.iconImageurl=""
        this.editMode = false;
      }
      this.formModal = true;
    },
    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.clerkInput.first_name) {
        errorMessage = "لطفا نام پرسنل را وارد کنید";
      }
      if (!errorMessage && !this.clerkInput.first_name) {
        errorMessage = "لطفا نام خانوادگی پرسنل را وارد کنید";
      }

      let formData = new FormData();

      if (this.clerkInput.first_name) {
        formData.append("first_name", this.clerkInput.first_name);
      }
      if (this.clerkInput.last_name) {
        formData.append("last_name", this.clerkInput.last_name);
      }
      if (this.clerkInput.code) {
        formData.append("code", this.clerkInput.code);
      }
      if (this.clerkInput.gender) {
        formData.append("gender", this.clerkInput.gender);
      }
      if (this.clerkInput.space_id) {
        formData.append("space_id", this.clerkInput.space_id);
      }
      if (this.clerkInput.mobile) {
        formData.append("mobile", this.clerkInput.mobile);
      }
      if (this.clerkInput.shift_work) {
        formData.append("shift_work", this.clerkInput.shift_work);
      }
      if (this.clerkInput.organizational_unit) {
        formData.append("organizational_unit", this.clerkInput.organizational_unit);
      }
      if (this.clerkInput.monthly_salary) {
        formData.append("monthly_salary", this.clerkInput.monthly_salary);
      }
      if (this.clerkInput.monthly_daily) {
        formData.append("monthly_daily", this.clerkInput.monthly_daily);
      }
      if (this.clerkInput.reward) {
        formData.append("reward", this.clerkInput.reward);
      }
      if (this.clerkInput.bank_account) {
        formData.append("bank_account", this.clerkInput.bank_account);
      }
      if (this.clerkInput.bank_sheba) {
        formData.append("bank_sheba", this.clerkInput.bank_sheba);
      }
      if (this.clerkInput.bank_number) {
        formData.append("bank_number", this.clerkInput.bank_number);
      }
      if (this.clerkInput.bank_name) {
        formData.append("bank_name", this.clerkInput.bank_name);
      }
      if (this.clerkInput.status) {
        formData.append("status", this.clerkInput.status);
      }
      if (this.clerkInput.description) {
        formData.append("description", this.clerkInput.description);
      }
      if (this.clerkInput.address) {
        formData.append("address", this.clerkInput.address);
      }
      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.submitLoading = true;

      const requestData = {
        method: this.editMode ? "put" : "post",
        url: this.editMode
            ? `/admin/clerks/${this.clerkInput.id}`
            : `/admin/clerks`,
      //  data: formData,
        data: this.clerkInput,
      };

      this.$http(requestData)
          .then((response) => {
            let message = "پرسنل با موفقیت اضافه شد";
            if (this.editMode) message = "پرسنل با موفقیت ویرایش شد";
            this.$bvToast.toast(message, {
              title: this.$t("Done"),
              variant: "success",
              solid: true,
            });
            if (!this.editMode && this.buildings && this.buildings.length > 0) {
              this.$refs.residencesPaginationComponent.clear();
              // this.$refs.residencesSearchComponent.clear();
              this.$refs.residencesTableHeadComponent.clear();
              this.page = 1;
              this.searchInput = "";
              this.sortBy = "";
              this.sortDir = "";
            }

            this.submitLoading = false;
            this.formModal = false;
            this.getClerks();
          })
          .catch((err) => {
            let message = "خطا در ایجاد پرسنل";
            if (this.editMode) message = "خطا در ویرایش پرسنل";
            if (
                err.response &&
                err.response.data &&
                err.response.data.response &&
                err.response.data.response.message
            )
              message = err.response.data.response.message;
            if (
                err.response &&
                err.response.data &&
                err.response.data.response &&
                err.response.data.response.meta &&
                err.response.data.response.meta.errors
            )
              this.errorMessage = err.response.data.response.meta.errors;
            console.log("this.errorMessage", this.errorMessage);

            this.$bvToast.toast(message, {
              title: this.$t("Error"),
              variant: "danger",
            });
            this.submitLoading = false;
            console.log(err);
          });
    },
  },


};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>