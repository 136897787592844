<template>
  <b-row class="justify-center">
    <b-col sm="12" md="4">
      <span class="mr-1 text-capitalize">
        {{ $t("search") }}
        بر اساس
      </span>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          id="radio-group-2"
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          name="radio-sub-component"
        >
          <b-form-checkbox value="name">نام</b-form-checkbox>
          <b-form-checkbox value="mobile">موبایل</b-form-checkbox>
          <b-form-checkbox value="email">ایمیل</b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
    </b-col>
    <b-col>
      <b-row>
        <b-col sm="12" md="8">
          <b-input-group>
            <b-form-input
              :disabled="selected.length == 0"
              v-model="searchInput"
              placeholder=""
            />
            <b-input-group-append v-if="isSearchResult">
              <b-button @click="searchBase('delete')" variant="danger"
                >X</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col>
          <b-button variant="primary" block @click="searchBase()">
            <span v-if="submitLoading">
              <b-spinner small class="p-0 m-0" />
              <span class="sr-only">Loading...</span>
            </span>

            <div v-else class="text-capitalize">جستجو</div>
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckboxGroup,
  BFormCheckbox,
  BButton,
  BRow,
  BCol,
  BSpinner,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  props: ["value", "selectedFilter", "submitLoading", "isSearchResult"],
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BFormCheckbox,
    BButton,
    BRow,
    BSpinner,
    BCol,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      searchInput: "",
      selected: this.$props.selectedFilter,
    };
  },
  computed: {},
  methods: {
    clear() {
      this.searchInput = "";
    },
    searchBase(type) {
      if (type == "delete") {
        this.clear();
      }
      this.$emit("searchBase", {
        searchInput: type == "delete" ? "" : this.searchInput,
        selectedFilter: this.selected,
        type: type,
      });
    },
  },
  watch: {
    searchInput(value) {
      this.$emit("input", this.searchInput);
    },
  },
};
</script>
