<template>
  <b-row class="breadcrumbs-top">
    <b-col cols="12">
      <h2 class="content-header-title float-left pr-1 mb-0 text-capitalize">
        {{ $t($route.meta.pageTitle) }}
      </h2>
      <div class="breadcrumb-wrapper">
        <b-breadcrumb>
          <b-breadcrumb-item to="/">
            <feather-icon
              icon="HomeIcon"
              size="16"
              class="align-text-top"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item
          class="text-capitalize"
            v-for="item in $route.meta.breadcrumb"
            :key="item.text"
            :active="item.active"
            :to="item.to"
          >
            {{ $t(item.text) }}
          </b-breadcrumb-item>
             <b-breadcrumb-item v-if="lastItemName" :active="true" >
           {{ lastItemName }}
          </b-breadcrumb-item>
        </b-breadcrumb>
     
      </div>
    </b-col>
  </b-row>
</template>


<script>
  import { BBreadcrumb, BBreadcrumbItem, BRow, BCol } from "bootstrap-vue";

  export default {
    props:['lastItemName'],
    components: {
      BBreadcrumb,
      BBreadcrumbItem,
      BRow,
      BCol,
    },
  };
</script>


<style>
</style>