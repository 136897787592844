<template>
  <div v-if="errorMessages">
    <template
      v-if="
      errorMessages &&
        typeof errorMessages[errorType] !== 'undefined' &&
        errorMessages[errorType].length > 0
      "
    >
    <div v-for="(error,index) in errorMessages[errorType]" :key="index" class="invalid-feedback d-flex">
      {{ error }}
    </div>
    </template>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { BFormGroup } from "bootstrap-vue";

export default {
  props: ["errorType", "errorMessages"],
  components: {
    vSelect,
    BFormGroup,
  },
  data() {
    return {
     
    };
  },
 
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
